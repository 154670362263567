<template>
  <v-badge :value="selectedOptions.length > 0" overlap bordered>
    <v-select
      ref="vSelect"
      v-model="selectedOptions"
      :label="label"
      outlined
      :items="options"
      :menu-props="{ maxHeight: '400px', offsetY: true }"
      multiple
      hide-details
      background-color="white"
      style="max-width: 220px"
      :item-text="itemText"
      :item-value="itemValue"
      :return-object="returnObject"
      @blur="change"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0 && selectedOptions.length === 1">{{ item.text ? item.text : item.name }}</span>
        <span v-if="index === 0 && selectedOptions.length > 1">{{ selectedOptions.length }} selecionados</span>
      </template>

      <template #append-item>
        <div class="d-flex justify-end mx-2 mt-2" style="border-top: 1px solid #8080804f;">
          <mf-button class="mt-2" label="Limpar" outlined color="error" text size="sm" @click="clear" />
          <mf-button class="mt-2" color="primary" label="Aplicar" size="sm" @click="onClick" />
        </div>
      </template>
    </v-select>
  </v-badge>
</template>

<script>
export default {
  name: 'SelectOptionsFilter',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedOptions: []
  }),
  watch: {
    value(newValue, oldValue) {
      this.selectedOptions = this.value
    }
  },
  mounted() {
    this.selectedOptions = this.value
  },
  methods: {
    clear() {
      this.selectedOptions = []
      this.change()
      this.$refs.vSelect.blur()
    },
    onClick() {
      this.change()
      this.$refs.vSelect.blur()
    },
    change() {
      this.$emit('change', this.selectedOptions)
    }
  }
}
</script>
